import { initializeApp } from "firebase/app";
// import { getAuth, GoogleAuthProvider, connectAuthEmulator} from "firebase/auth";
// // import 'firebase/firestore';
import config from './config';

const FirebaseApp = initializeApp(config.firebase);

// export const Providers = {
//   google: new GoogleAuthProvider()
// }

// export const auth = getAuth(FirebaseApp);
// // [TODO] 로컬에서만 에뮬레이터에 연결하도록 수정 필요
// connectAuthEmulator(auth, "http://localhost:9099"); 
export default FirebaseApp;