import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import jpn from './ja';
import kor from './ko';

export const languages = ['ko', 'ja'] as const;
export type Languages = typeof languages[number]; // 'ko' | 'ja'

const resources = {
  ja: { translation: jpn },
  ko: { translation: kor },
}

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('language') || 'ko',
  fallbackLng: 'ko',
  keySeparator: ".",
  interpolation: {
    escapeValue: false
  }
})

export default i18n;