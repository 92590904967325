import { FC, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Clear from "@mui/icons-material/Clear";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import useMediaQuery from '@mui/material/useMediaQuery';
import MainTheme from '../theme/mainTheme';

import * as API from '../api';

import IRequestCouponData from "../api/dto/IRequestCouponData";
// import { PAGE_LOADING } from "../store/reducers/authSlice";
// import { useAppDispatch } from "../store/hooks";
import { Dimmer, ModalStyle, PageLoadingProgressStyle } from "../styles/styles";

interface IProps {
}

const CouponForm: FC<IProps> = () => {
  // const dispatch = useAppDispatch();

  const [pid, setPid] = useState<string>("");
  const [couponCode, setCouponCode] = useState<string>("");

  const [open, setOpen] = useState(false); // 모달 오픈
  const [modalText, setModalText] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const isSmUp = useMediaQuery(MainTheme.breakpoints.up('sm'));
  const isLgUp = useMediaQuery(MainTheme.breakpoints.up('lg'));

  const { i18n } = useTranslation();

  const { t } = useTranslation();
  // const navigate = useNavigate();

  const handleSend = async () => {
    if (!pid) {
      setModalText(`error.e_msg_invalid_pid`);
      setOpen(true);
    }
    else if (!couponCode) 
    {
      setModalText(`error.7300`);
      setOpen(true);
    } else {
      const data: IRequestCouponData = {
        pid: pid,
        coupon_code: couponCode,
      }
      API.Contents.coupon(data)
        .then((res) => {
          setLoading(false);
          setModalText(`sys.use_coupon_complete`);
          setOpen(true);
        })
        .catch((e) => {
          const err = JSON.parse(e.message);
          setLoading(false);
          setModalText(`error.${err.code}`);
          setOpen(true);
          // alert(t(`error.${err.code}`));
        })
        .finally(() => {
          // navigate(-1);
          // dispatch(
          //   PAGE_LOADING(false)
          // );
        });
        setLoading(true);
      // dispatch(
      //   PAGE_LOADING(true)
      // );
    }

  }

  return (
    <Fragment>
    <Box
      component="main"
      maxWidth="lg"
      minWidth="sm"
      alignItems="center"
      sx={isLgUp? {
        width: "40vw",
      } : isSmUp? {
        width: '50vw',
        } :
        {
          width: "100vw",
        }
      }
    >
      <Box
        sx={{
          bgcolor: 'background.paper',
          width: '100%',
          height: '100%'
        }}
      >
        <Stack spacing={1} sx={{ mt: 0, mb: 1, alignItems: 'center' }}>
          <Box
            sx={{
              mt: 1,
              mb: 1
            }}
          >
            <Typography
            variant="h4"
            color="text.primary"
            align="center"
            sx={{ mt: 6, mb: 1 }}
            >
            {t(`sys.use_coupon`)}
          </Typography>
          </Box>
        </Stack>
        <Stack spacing={1} sx={{ mt: 1, mb: 7, alignItems: 'center' }}>
          <Divider flexItem variant="fullWidth"/>
        </Stack>
        <Stack spacing={1} sx={{ mt: 1, mb: 1, alignItems: 'center' }}>
          <TextField
            required
            id="input_pid"
            // label="플레이어ID 입력"
            label={t(`sys.input_pid`)}
            value={pid}
            onChange={(e) => setPid(e.target.value)}
            name="input_pid"
            sx={{width: '80%', mt: 1, mb: 1}}
          />
        </Stack>
        <Stack spacing={1} sx={{ mt: 1, mb: 1, alignItems: 'center' }}>
          <TextField
            required
            id="coupon_code"
            // label="쿠폰번호 입력"
            label={t(`sys.input_coupon_code`)}
            name="coupon_code"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            sx={{width: '80%', alignContent: 'center'}}
          />
        </Stack>
        <Stack sx={{ mt: 2, mb: 1, alignItems: 'center' }}>
          <Button
            variant="contained"
            onClick={() => handleSend()}
            sx={{ mt: 2, mb: 1, width: '80%' }}
          >
            {t("sys.confirm")}
          </Button>
        </Stack>
        <Stack spacing={1} sx={{ mt: 5, mb: 0, pb: 5, alignItems: "center"}}>
          {
            i18n.language === "ja"? 
            <img className="Image" alt ="Header" src={`${process.env.PUBLIC_URL}/img/ap_coupon_info_jp.png`} width={isSmUp? "85%": "90%"}/>
            : <img className="Image" alt ="Header" src={`${process.env.PUBLIC_URL}/img/ap_coupon_info_ko.png`} width={isSmUp? "85%": "100%"}/>
          }
        </Stack>
      </Box>
    </Box>
    {open ?
      <Box sx={{ ...Dimmer }}>
        <Modal
          hideBackdrop
          open={open}
          onClose={() => setOpen(false)}
          sx={{
            ...ModalStyle, 
            width: "350px", 
            height: "300px",
          }}
        >
          <Box component="main" sx={{mt: 2}}>
          <Grid container spacing={1} justifyContent="flex-start" alignSelf="center">
            <Grid item xs={11}>
            </Grid>
            <Grid item xs={1}>
              <Button
                onClick={() => setOpen(false)}
              >
                <Clear />
              </Button>
            </Grid>
          </Grid>
          <Typography
            variant="h6"
            color="text.primary"
            align="center"
            sx={{ mt: 1, mb: 1 }}
          >
            {t(`sys.use_coupon_info`)}
          </Typography>
          <Divider flexItem variant="fullWidth"/>
            <Typography
              variant="subtitle1"
              color="text.primary"
              align="center"
              sx={{ mt: 2, mb: 4 }}
            >
            {t(modalText).split("\n").map((line)=>(<div>{line}</div>))}
            </Typography>
            <Button
              fullWidth
              variant="contained"
              onClick={() => setOpen(false)}
              sx={{ mt: 3, mb: 2 }}
            >
            {t('sys.confirm')}
            </Button>
          </Box>
        </Modal>
      </Box>
      : null}
      {loading? 
      <Box sx={{ ...Dimmer }}>
        <Container maxWidth="xl" sx={{ ...PageLoadingProgressStyle }} >
          <CircularProgress />
        </Container>
      </Box>
      : null}
      </Fragment>
  );
}
export default CouponForm;
