import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import type IAuthState from '../../interfaces/IAuthState'
import i18n from "../../locales";

const initialState: IAuthState = {
  user: null,
  authenticated: false,
  loading: false,
  pageLoading: true,
  error: '',
  needVerification: false,
  success: '',
  auth: null,
  // accounts: [],
  lang: i18n.language || 'ko',
}

export const slice = createSlice(
  {
    name: "auth",
    initialState,
    reducers: {
      SET_USER: (state, { payload }) => {
        state.user = payload.user;
        state.auth = payload.auth;
      },
      SET_AUTH: (state, { payload }) => {
        state.auth = payload;
      },
      SET_ERROR: (state, { payload }) => {
        state.error = payload.error;
      },
      SIGN_OUT: (state, action) => {
        // state.user = null;
        state.authenticated = false;
        state.loading = false;
        state.error = '';
        state.needVerification = false;
        state.success = '';
        state.auth = null;
        // state.accounts = [];
        state.lang = "ko";
      },
      // SET_ACCOUNTS: (state, { payload }) => {
      //   state.accounts = payload.accounts;
      // },
      // SET_ACCOUNT: (state, { payload }) => {
      //   state.accounts = state.accounts
      //     .map((account) => account.pid === payload.account.pid
      //       ? payload.account
      //       : account
      //     );
      // },
      // DELETE_ACCOUNT: (state, { payload }) => {
      //   state.accounts = state.accounts.filter((x) => x.pid !== payload.account.pid);
      // },
      SET_LANGUAGE: (state, { payload }) => {
        state.lang = payload.lang;
      },
      PAGE_LOADING: (state, { payload }) => {
        state.pageLoading = payload;
      },
    }
  }
)

export const {
  // SET_USER,
  SET_AUTH,
  SET_ERROR,
  SIGN_OUT,
  // SET_ACCOUNTS,
  // SET_ACCOUNT,
  // DELETE_ACCOUNT,
  SET_LANGUAGE,
  PAGE_LOADING,
} = slice.actions;

// export const selectUser = (state: RootState) => state.auth.user;

export default slice.reducer;