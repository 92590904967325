
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import FirebaseApp from '../config/firebase';
import IRequestCouponData from "./dto/IRequestCouponData";
import IResponseCouponData from "./dto/IResponseCouponData";

const functions = getFunctions(FirebaseApp, "asia-northeast1");
// [TODO] 로컬에서만 에뮬레이터 연결 하도록 수정 필요
// connectFunctionsEmulator(functions, "localhost", 5001);

export const Contents = {
  coupon: httpsCallable<IRequestCouponData, IResponseCouponData>(
    functions, "page-coupon"
  ),
}
