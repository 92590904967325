import Box from '@mui/material/Box';

export default function CouponHeader() {
  return (
    <header>
      <Box sx={{ 
        display: "flex", 
        flexGrow: 1, 
        overflow: "hidden",
        width: "100%",
        height: "auto",
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        }}>
        <img className="Image" alt="Header" src={`${process.env.PUBLIC_URL}/img/ascent_protocol_header_ko.png`} height="auto"/>
      </Box>
    </header >
  );
}