import { FC, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import CouponInputForm from '../../components/CouponInputForm';
import CouponHeader from '../../components/CouponHeader';

import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from "@mui/material/Stack";
import MainTheme from '../../theme/mainTheme';
import CssBaseline from '@mui/material/CssBaseline';
import Copyright from '../../components/Copyright';
import { languages, Languages } from '../../locales';
import Container from "@mui/material/Container";

const CouponPage: FC = () => {
  // console.log("CouponPage 실행");

  const [searchParams, setSearchParams] = useSearchParams();

  const { i18n } = useTranslation();

  useEffect(() => {
    let lang = searchParams.get("lang") || "ja";
    if (languages.findIndex((x) =>  x === lang) === -1) {
      lang = "ja";
    }
  
    const langInput = lang as Languages
    i18n.changeLanguage(langInput)
        .then((_) => {
          localStorage.setItem('language', lang);
          // console.log('변경완료: ', lang, i18n.language);
        })
  }, []);
  
  return (
    <ThemeProvider theme={MainTheme}>
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <CssBaseline />
      <Container maxWidth={false} disableGutters component='main'>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <CouponHeader />
        <Stack>
        <Box component="main"
          sx={{
            flex: 1,
            bgcolor: '#eaeff1',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <CouponInputForm />
        </Box>
        </Stack>
        
        <Box 
          component="footer"
          sx={{
            bgcolor: '#ffffff',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            height: "10vh",
          }}>
          <Copyright sx={{ mt: 4, mb: 4 }} />
        </Box>
      </Box>
      </Container>
    </Box>
  </ThemeProvider>
  );
}

export default CouponPage;