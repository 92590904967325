import React, { useEffect } from "react";
import { Route, useParams, Routes } from 'react-router-dom';
import AuthRoute from './components/AuthRoute';
import PageLoadingProgress from "./components/PageLoadingProgress";
// import { auth } from './config/firebase';
import logging from './config/logging';
import routes from './config/routes';
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { PAGE_LOADING } from "./store/reducers/authSlice";

export interface IAppProps { }

const App: React.FunctionComponent<IAppProps> = (props) => {
  const params = useParams();

  const dispatch = useAppDispatch();
  const pageLoading = useAppSelector((state) => state.auth.pageLoading);

  useEffect(() => {
    // auth.onAuthStateChanged(user => {
    //   if (user) {
    //     logging.info('User detected.');
    //   }
    //   else {
    //     logging.info('No user detected');
    //   }

      dispatch(
        PAGE_LOADING(false)
      );
    // })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (pageLoading) {
    return <PageLoadingProgress />
  }

  return (
    <div>
      <Routes>
        {
          routes.map((route, index) =>
            <Route
              key={index}
              path={route.path}
              element={
                route.protected ?
                  <AuthRoute requiredAuth={route.requiredAuth}><route.component  {...params} /></AuthRoute> :
                  <route.component  {...params} />
              }
            />
          )
        }
      </Routes>
    </div>
  );
}

export default App;