import { configureStore } from '@reduxjs/toolkit'
import AuthReducer from './reducers/authSlice';
import CacheReducer from './reducers/cacheSlice';

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    cache: CacheReducer,
  },
  // middleware: [],
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch