import React from 'react';
import { Navigate } from 'react-router-dom';
// import { auth } from '../config/firebase';
import logging from '../config/logging';
import { AuthType } from '../interfaces/IAuth';
import { useAppSelector } from '../store/hooks';

export interface IAuthRouteProps {
  children: any,
  requiredAuth: AuthType[] | undefined,
}

const AuthRoute: React.FunctionComponent<IAuthRouteProps> = (props) => {
  const { children, requiredAuth } = props;
  const currentUser = useAppSelector((state) => state.auth.user);
  const auth = useAppSelector((state) => state.auth.auth);

  // if (!auth.currentUser) {
  if (!currentUser) {
    logging.warn('No user detected, redirecting');
    return <Navigate replace to="/login" />;
  }
  if (requiredAuth) {
    if (auth === null) {
      logging.warn('No has auth, redirecting');
      return <Navigate replace to="/500" />;
    } else {
      const hasAuth = requiredAuth.every((authType) => auth[authType]);
      if (hasAuth === false) {
        logging.warn(`No has ${requiredAuth}, redirecting`);
        return <Navigate replace to="/500" />;
      }
    }
  }

  return (
    <div>{children}</div>
  );
}

export default AuthRoute;