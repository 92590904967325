import { createSlice } from "@reduxjs/toolkit";
import type ICacheState from '../../interfaces/ICacheState'

const initialState: ICacheState = {
  users: [],
  items: [],
  students: [],
  stages: [],
}

export const slice = createSlice(
  {
    name: "cache",
    initialState,
    reducers: {
      // SET_USER: (state, { payload }) => {
      //   state.users = state.users.map((x) => x.pid === payload.pid ? payload : x);
      // },
      // SET_USERS: (state, { payload }) => {
      //   state.users = payload;
      // },
      // DELETE_USER: (state, { payload }) => {
      //   state.users = state.users.filter((x) => x.pid !== payload.user.pid);
      // },
      // SET_ITEM: (state, { payload }) => {
      //   state.items = state.items.map((x) => x.uid === payload.uid ? payload : x);
      // },
      // SET_ITEMS: (state, { payload }) => {
      //   state.items = payload;
      // },
      // DELETE_ITEM: (state, { payload }) => {
      //   state.items = state.items.filter((x) => x.uid !== payload);
      // },
      // SET_STUDENT: (state, { payload }) => {
      //   state.students = state.students.map((x) => x.id === payload.id ? payload : x);
      // },
      // SET_STUDENTS: (state, { payload }) => {
      //   state.students = payload;
      // },
      // SET_STAGE: (state, { payload }) => {
      //   state.stages = state.stages.map((x) => x.id === payload.id ? payload : x);
      // },
      // SET_STAGES: (state, { payload }) => {
      //   state.stages = payload;
      // },
      // DELETE_STAGE: (state, { payload }) => {
      //   state.stages = state.stages.filter((x) => x.id !== payload);
      // },
    }
  }
)

export const {
  // SET_USER,
  // SET_USERS,
  // DELETE_USER,
  // SET_ITEM,
  // SET_ITEMS,
  // DELETE_ITEM,
  // SET_STUDENT,
  // SET_STUDENTS,
  // SET_STAGE,
  // SET_STAGES,
  // DELETE_STAGE,
} = slice.actions;

export default slice.reducer;