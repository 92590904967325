import { FC } from 'react';
import Typography from '@mui/material/Typography';

export interface ICopyrightProps {
  sx?: any;
  href?: string;
  text?: string;
}

const Copyright: FC<ICopyrightProps> = (props) => {
  return (
    // <Grid container spacing={1} justifyContent="flex-start" alignSelf="center">
    //   <Grid item xs={1} alignItems="center">
    //     <img
    //         className="Image"
    //         alt="logo"
    //         src={`${process.env.PUBLIC_URL}/img/ASTA GAMES_Bi.png`}
    //       />
    //   </Grid>
    //   <Grid item xs={2} alignItems="center">
    //     <Typography
    //       variant="body2"
    //       color="text.secondary"
    //       align="center"
    //       sx={props.sx || { mt: 8, mb: 4 }}
    //     >
    //       © A* Games All Rights Reserved.
    //     </Typography>
    //   </Grid>
    // </Grid>
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={props.sx || { mt: 8, mb: 4 }}
    >
      © A* Games All Rights Reserved.
    </Typography>
  );
}

export default Copyright;