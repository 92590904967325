import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import { PageLoadingProgressStyle } from "../styles/styles";

const PageLoadingProgress = () => {
  return (
    <Container maxWidth="xl" sx={{ ...PageLoadingProgressStyle }} >
      <CircularProgress />
    </Container>
  );
}
export default PageLoadingProgress