import IRoute from "../interfaces/IRoute";
// import HomePage from "../pages/home";
import CouponPage from "../pages/ascent-protocol/coupon";

const routes: IRoute[] = [
  {
    path: '/',
    component: CouponPage,
    name: 'Home Page',
    protected: true
  },
  {
    path: '/ascent-protocol/coupon',
    component: CouponPage,
    name: "Coupon Page",
    protected: false,
  }
]

export default routes;
