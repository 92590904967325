export const RoleAuthModalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '60%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export const PageLoadingProgressStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: '100vh',
};

export const ModalStyle = {
  // position: 'absolute' as 'absolute',
  // position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: "flex",
  alignItems: "top",
  justifyContent: "center",
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  overflowY: 'auto',
};

export const Dimmer = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  width: '100%',
  height: '100%',
  opacity: '40%',
  bgcolor: 'rgb(38, 38, 38)',
  zIndex: 100,
}

export const TableHeadStyle = {
  bgcolor: '#009be5'
}